/* eslint-disable jsx-a11y/anchor-is-valid */
import "svgmap/dist/svgMap.min.css";
import Sidebar from "../../components/dashboard/sidebar.jsx";
import { Badge, Dropdown, Flowbite, Table, useTheme } from "flowbite-react";
import Navbar from "../../components/dashboard/navbar.jsx"
import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import {
  PlusIcon, ExclamationTriangleIcon
} from '@heroicons/react/24/outline'
import axios from 'axios'

import FlowbiteFix from "../../components/dashboard/flowbitefix.jsx";
import { initFlowbite } from 'flowbite'

function isValidUrl(str) {
  const pattern = new RegExp(
    "^([a-zA-Z]+:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  return pattern.test(str);
}

const headers = {
  'Content-Type': 'application/json'
}

const CreateLinksPage = function () {
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [destination, setDestination] = useState('');

  const handleChange = event => {
    setDestination(event.target.value);
  };

  const handleClick = (destination) => {
    if(!isValidUrl(destination)) {
      setIsValid(false)
      return;
    }

    let postData = {
      customer_name: 'default',
      url_item_count: 1,
      url: destination
    };

    let axiosConfig = {
      headers: {
          'Content-Type': 'application/json',
      }
    };

    setIsWaiting(true);
    axios.post('https://api.itstiny.link/api/urls', postData, axiosConfig)
    .then(function (response) {
      if(response.status === 200){
        //success
        setOpenSuccess(true);
      } else {
        //error
        setOpenError(true);
      }
      setIsWaiting(false)
    })
    .catch(function (error) {
      console.log(error);
      setIsWaiting(false)
    });
    //clean outputs after submit
    setDestination('');
    setIsValid(true)
  }

    return (
      <>
      <FlowbiteFix/>
        <div className="bg-gray-50 dark:bg-gray-900 antialised">
          <Navbar />
          <div className="mx-auto flex w-full h-full relative pt-4 xl:pt-8 px-4">
            <Sidebar />
            <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
              <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
                Add a new link
              </h2>
              <form action="#">
                <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                  <div className="sm:col-span-2 md:col-span-3">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Destination
                    </label>
                    {
                      !isValid ?
                      (<>
                        <label
                          htmlFor="name"
                          className="block mb-2 text-sm font-medium text-red-500 dark:text-white"
                        >
                          Please enter a valid URL
                        </label>
                      </>) : null
                    }
                    <input
                      type="text"
                      name="name"
                      id="name"
                      onChange={handleChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="https://www.google.com"
                      value={destination}
                      required={true}
                    />
                  </div>
                  <div className="sm:col-span-2 md:col-span-3">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Title (optional)
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder=""
                      required=""
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="account"
                      className="inline-flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Custom Link Alias (optional)
                      <button
                        type="button"
                        data-tooltip-target="tooltip-account"
                        data-tooltip-style="dark"
                        className="ml-1"
                      >
                        <svg
                          aria-hidden="true"
                          className="w-4 h-4 text-gray-400 hover:text-gray-900 dark:hover:text-white dark:text-gray-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="sr-only">Show information</span>
                      </button>
                      <div
                        id="tooltip-account"
                        role="tooltip"
                        className="inline-block absolute invisible z-10 py-2 px-3 max-w-sm text-xs font-normal text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                      >
                        Allows you to change the ending content of your URL
                        <div className="tooltip-arrow" data-popper-arrow="" />
                      </div>
                    </label>
                    <select
                      defaultValue="shorturl"
                      id="domain"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    >
                      <option value="shorturl">itstiny.link/</option>
                    </select>
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="price"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Alias
                    </label>
                    <input
                      type="text"
                      name="alias"
                      id="alias"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder=""
                      required=""
                    />
                  </div>
                </div> 
              </form>

              <button
                  onClick={() => handleClick(destination)}
                  className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800 disabled:bg-blue-400" disabled={isWaiting}
                >
                  {
                    isWaiting ?
                    (<>
                    <svg
                      className="animate-spin -ml-0.5 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx={12}
                        cy={12}
                        r={10}
                        stroke="currentColor"
                        strokeWidth={4}
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    <span className="ml-2">Create Link</span>
                    </>) : 
                    (<>
                      <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                      <span className="ml-2">Create Link</span>
                    </>)
                  }
              </button>

            </div>

            {openSuccess ? (
                <>
                  <Transition.Root show={openSuccess} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={setOpenSuccess}>
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                      </Transition.Child>

                      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                              <div>
                                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Link Created
                                  </Dialog.Title>
                                  <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                      View and manage your links in the manage section by clicking <u><a href="/manage-links">here</a></u>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-5 sm:mt-6">
                                <button
                                  type="button"
                                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  onClick={() => setOpenSuccess(false)}
                                >
                                  OK
                                </button>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition.Root>
                </>
              ) : null
            }

            {openError ? (
                <>
                  <Transition.Root show={openError} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={setOpenError}>
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                      </Transition.Child>

                      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                              <div>
                                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                  <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                  <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Error
                                  </Dialog.Title>
                                  <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                      Something went wrong, please try again.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-5 sm:mt-6">
                                <button
                                  type="button"
                                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  onClick={() => setOpenError(false)}
                                >
                                  OK
                                </button>
                              </div>
                            </Dialog.Panel>
                          </Transition.Child>
                        </div>
                      </div>
                    </Dialog>
                  </Transition.Root>
                </>
              ) : null
            }

          </div>
        </div>
      </>
    );
};

export default CreateLinksPage;