/* eslint-disable jsx-a11y/anchor-is-valid */
import "svgmap/dist/svgMap.min.css";
import Sidebar from "../../components/dashboard/sidebar.jsx";
import Chart from "react-apexcharts";
import NoUserLinks from "../../components/dashboard/NoUserLinks.jsx";
import { Badge, Dropdown, Table, useTheme } from "flowbite-react";
import Navbar from "../../components/dashboard/navbar.jsx"
import FlowbiteFix from "../../components/dashboard/flowbitefix.jsx";

const AnalyticsPage = function () {
    return (
      <>
      <FlowbiteFix/>
        <div className="bg-gray-50 dark:bg-gray-900 antialised">
        <Navbar />
        <div className="mx-auto flex w-full h-full relative pt-4 xl:pt-8 px-4">
          <Sidebar />
          <main className="flex-1 pb-4 h-full overflow-y-auto lg:pl-4">
            <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 mb-4">
              <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64">Analytics...</div>
              <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
              <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl"></div>
              <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
            </div>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
              </div>
              <div className="grid grid-cols-3 gap-4 mb-4">
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
              </div>
          </main>
        </div>


         
        </div>
      </>
    );
};

export default AnalyticsPage;