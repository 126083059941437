import { Routes, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";

import FlowbiteWrapper from "./components/flowbite-wrapper";
import LandingPage from "./pages/LandingPage.jsx";
import LandingPageTest from "./pages/LandingPageTest.jsx";
import LoginPage from "./pages/auth/LoginPage.jsx";
import RegisterPage from "./pages/auth/RegisterPage.jsx";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage.jsx";
import DashboardHomePage from "./pages/dashboard/HomePage.jsx";
import ManageLinksPage from "./pages/dashboard/ManageLinksPage.jsx"
import CreateLinksPage from "./pages/dashboard/CreateLinksPage.jsx";
import ReportingPage from "./pages/dashboard/ReportingPage.jsx";
import ProfileSettingsPage from "./pages/dashboard/ProfileSettingsPage.jsx";
import CreateQRPage from "./pages/dashboard/CreateQRPage.jsx";
import AnalyticsPage from "./pages/dashboard/AnalyticsPage.jsx";
import ManageSubscriptionPage from "./pages/dashboard/ManageSubscriptionPage.jsx";
import UnderConstruction from "./pages/UnderConstruction.jsx"

import './App.css';
import 'flowbite';
import 'flowbite-datepicker';

import ScrollToAnchor from "./helpers/ScrollToAnchor.jsx";

function App() {
  return (
    <BrowserRouter>
    <ScrollToAnchor />
      <Routes>
        <Route element={<FlowbiteWrapper />}>
          <Route path="/" element={<LandingPageTest />} />
          <Route path="/login" element={<UnderConstruction />} />
          <Route path="/register" element={<UnderConstruction />} />
          <Route path="/forgot-password" element={<UnderConstruction />} />
          <Route path="/home" element={<DashboardHomePage />} />
          <Route path="/manage-links" element={<ManageLinksPage />} />
          <Route path="/create-links" element={<CreateLinksPage />} />
          <Route path="/reporting" element={<ReportingPage />} />
          <Route path="/analytics" element={<AnalyticsPage />} />
          <Route path="/create-qr" element={<CreateQRPage />} />
          <Route path="/manage-subscription" element={<ManageSubscriptionPage />} />
          <Route path="/settings" element={<ProfileSettingsPage />} />
          {/* <Route path="/mailing/compose" element={<MailingComposePage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
