import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  LinkIcon,
  DocumentIcon,
  TagIcon,
  Cog8ToothIcon,
  CreditCardIcon,
  ArrowLeftOnRectangleIcon,
  QrCodeIcon,
  PlusIcon,
  WrenchScrewdriverIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

const navigation = [
  { name: 'Home', href: '/home', icon: HomeIcon, current: false },
  { name: 'Links', href: '/links', icon: LinkIcon, current: false },
  { name: 'QR Codes', href: '/links', icon: QrCodeIcon, current: false },
  { name: 'Analytics', href: '/analytics', icon: ChartPieIcon, current: false },
  { name: 'Reports', href: '/reports', icon: DocumentIcon, current: false },
  { name: 'Billing', href: '/reports', icon: CreditCardIcon, current: false },
]

const userNavigation = [
  { name: 'Your profile', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function toggleDrawer() {
  const drawer = document.getElementById("drawer-example");
  drawer.style.display = "block";
}

export default function Navbar(props) {
  for (let i in navigation) {
    if (navigation[i].name === props.name) {
        navigation[i].current = true
    }
  }
  const [sidebarOpen, setSidebarOpen] = useState(false)
  return (
    <>
      <div className="bg-gray-50 dark:bg-gray-900 antialised">
        <header>
          <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 left-0 right-0 top-0 z-50">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-center">
                <Link to="/home" className="flex mr-6">
                  <img
                    src="https://flowbite.s3.amazonaws.com/logo.svg"
                    className="mr-3 h-8"
                    alt="Flowbite Logo"
                  />
                  <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                   tinylink
                  </span>
                </Link>
              </div>
              <div className="flex items-center justify-center">
                {/* Dropdown */}
                <div className="flex-shrink-0 hidden lg:inline">
                  {/* <a className="text-gray-800 dark:text-white focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-1 py-2 lg:py-2.5 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800" href="/create-links"> 
                    <button
                      type="button"
                      className="relative inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
                    >
                      <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Create Link
                    </button>
                  </a> */}
                  <Link to="/create-links" className="text-gray-800 dark:text-white focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-1 py-2 lg:py-2.5 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"> 
                    <button
                      type="button"
                      className="relative inline-flex items-center gap-x-1.5 px-3 py-2 text-sm font-semibold text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
                    >
                      <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Create Link
                    </button>
                  </Link>
                </div>
                <span className="hidden mx-2 w-px h-5 bg-gray-200 dark:bg-gray-600 lg:inline lg:mx-2" />
                {/* Dropdown menu */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                    <span className="hidden lg:flex lg:items-center lg:pr-8">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        Profile
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        {/* {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                active ? 'bg-gray-50' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))} */}
                        <Link to="/settings" className="hover:bg-gray-50 block px-3 py-1 text-sm leading-6 text-gray-90"> 
                          Your Profile
                        </Link>
                        <Link to="/" className="hover:bg-gray-50 block px-3 py-1 text-sm leading-6 text-gray-90"> 
                          Sign Out
                        </Link>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                <button
                  type="button"
                  id="drawer-navigation-button"
                  data-drawer-target="sidebar"
                  data-drawer-toggle="sidebar"
                  aria-controls="sidebar"
                  className="items-center p-2 text-gray-500 rounded-lg lg:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                >
                  <span className="sr-only">Open menu</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </nav>
        </header>   
      </div>

    </>
  )
}