import { initFlowbite } from 'flowbite'
import { useEffect } from 'react'
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  LinkIcon,
  DocumentIcon,
  TagIcon,
  Cog8ToothIcon,
  CreditCardIcon,
  ArrowLeftOnRectangleIcon,
  QrCodeIcon,
  PlusIcon,
  WrenchScrewdriverIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import Navbar from "./navbar.jsx"
import { Link } from 'react-router-dom'
import { Navigate, useNavigate } from 'react-router-dom'
import FlowbiteFix from './flowbitefix.jsx'

const navigation = [
  { name: 'Home', href: '/home', icon: HomeIcon, current: false },
  { name: 'Links', href: '/links', icon: LinkIcon, current: false },
  { name: 'QR Codes', href: '/links', icon: QrCodeIcon, current: false },
  { name: 'Analytics', href: '/analytics', icon: ChartPieIcon, current: false },
  { name: 'Reports', href: '/reports', icon: DocumentIcon, current: false },
  { name: 'Billing', href: '/reports', icon: CreditCardIcon, current: false },
]

const userNavigation = [
  { name: 'Your profile', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Sidebar(props) {
  useEffect(() => {
    initFlowbite();
  }, []);
  for (let i in navigation) {
    if (navigation[i].name === props.name) {
        navigation[i].current = true
    }
  }
  const [sidebarOpen, setSidebarOpen] = useState(false)
  return (
    <>  
          <div>
            <aside
              id="sidebar"
              className="fixed top-0 left-0 z-40 lg:z-0 w-64 h-screen transition-transform -translate-x-full bg-white border-r border-gray-200 lg:translate-x-0 dark:bg-gray-800 dark:border-gray-700 lg:block lg:relative lg:h-auto lg:rounded-lg lg:border-r-0"
              aria-label="Sidebar"
            >
              <div className="overflow-y-auto py-5 px-3 h-full bg-white dark:bg-gray-800 lg:rounded-lg">
                <Link
                  to="/settings"
                  className="flex justify-between items-center p-2 mb-5 w-full rounded-lg dark:bg-gray-800 dark:hover:bg-gray-700 hover:bg-gray-50 dark:hover-bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700"
                  type="button"
                >
                  <span className="sr-only">Open user menu</span>
                  <div className="flex items-center">
                    <img
                      src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png"
                      className="mr-3 w-8 h-8 rounded-full"
                      alt="Bonnie avatar"
                    />
                    <div className="text-left">
                      <div className="font-semibold leading-none text-gray-900 dark:text-white mb-0.5">
                        Bonnie Green
                      </div>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        bonnie@flowbite.com
                      </div>
                    </div>
                  </div>
                </Link>
                <div className="my-5 py-5 border-y border-gray-100 dark:border-gray-700">
                  <ul className="grid grid-cols-3 gap-2">
                    <li>
                    <Link className="rounded-xl bg-primary-50 hover:bg-primary-100 dark:bg-primary-900 dark:hover:bg-primary-800 p-2.5 flex flex-col items-center justify-center group" to="/create-links">
                          <span className="w-8 h-8 rounded-full bg-primary-100 dark:bg-primary-800 flex items-center justify-center mb-1 group-hover:bg-primary-200  dark:group-hover:bg-primary-700">
                            <LinkIcon
                              className="w-5 h-5 text-primary-600 dark:text-primary-300"
                              aria-hidden="true"
                            />
                          </span>
                          <span className="text-sm text-primary-600 dark:text-primary-300 font-medium">
                            Links
                          </span>
                      </Link>
                    </li>
                    <li>
                    <Link className="rounded-xl bg-purple-50 hover:bg-purple-100 dark:bg-purple-900 dark:hover:bg-purple-800 p-2.5 flex flex-col items-center justify-center group" to="/create-qr"> 
                        <span className="w-8 h-8 rounded-full bg-purple-100 dark:bg-purple-800 flex items-center justify-center mb-1 group-hover:bg-purple-200  dark:group-hover:bg-purple-700">
                          <QrCodeIcon
                            className="w-5 h-5 text-purple-600 dark:text-purple-300"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="text-sm text-purple-600 dark:text-purple-300 font-medium">
                          QR
                        </span>
                      </Link>
                    </li>
                    <li>
                    <Link className="rounded-xl bg-teal-50 hover:bg-teal-100 dark:bg-teal-900 dark:hover:bg-teal-800 p-2.5 flex flex-col items-center justify-center group" to="/analytics"> 
                        <span className="w-8 h-8 rounded-full bg-teal-100 dark:bg-teal-800 flex items-center justify-center mb-1 group-hover:bg-teal-200  dark:group-hover:bg-teal-700">
                          <ChartPieIcon
                            className="w-5 h-5 text-teal-600 dark:text-teal-300"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="text-sm text-teal-600 dark:text-teal-300 font-medium">
                          Analytics
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <ul className="space-y-2">
                  <li>
                  <Link className="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" to="/home"> 
                      <HomeIcon
                        className="w-6 h-6 text-gray-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                      />
                      <span className="ml-3">Home</span>
                    </Link>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="flex items-center p-2 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      aria-controls="dropdown-links"
                      data-collapse-toggle="dropdown-links"
                    >
                      <LinkIcon
                        className="w-6 h-6 text-gray-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                      />
                      <span className="flex-1 ml-3 text-left whitespace-nowrap">Links</span>
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    <ul id="dropdown-links" className="hidden py-2 space-y-2">
                      <li>
                      <Link className="flex items-center p-2 pl-11 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" to="/create-links"> 
                          <PlusIcon
                            className="w-6 h-6 text-gray-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            aria-hidden="true"
                          />
                          <span className="flex-1 ml-3 text-left whitespace-nowrap">Create Links</span>
                        </Link>
                      </li>
                      <li>
                        <Link className="flex items-center p-2 pl-11 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" to="/manage-links"> 
                          <WrenchScrewdriverIcon
                            className="w-6 h-6 text-gray-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            aria-hidden="true"
                          />
                          <span className="flex-1 ml-3 text-left whitespace-nowrap">Manage Links</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                  <Link className="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" to="/create-qr"> 
                      <QrCodeIcon
                        className="w-6 h-6 text-gray-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                      />
                      <span className="flex-1 ml-3 whitespace-nowrap">QR Codes</span>
                    </Link>
                  </li>
                  <li>
                  <Link className="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" to="/analytics">
                      <ChartPieIcon
                        className="w-6 h-6 text-gray-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                      />
                      <span className="flex-1 ml-3 whitespace-nowrap">
                        Analytics
                      </span>
                    </Link>
                  </li>
                  <li>
                  <Link className="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" to="/reporting">
                      <DocumentIcon
                        className="w-6 h-6 text-gray-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                      />
                      <span className="flex-1 ml-3 whitespace-nowrap">
                        Reporting
                      </span>
                    </Link>
                  </li>
                </ul>
                <ul className="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
                  <li>
                  <Link className="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" to="/settings">
                      <Cog8ToothIcon
                        className="w-6 h-6 text-gray-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                      />
                      <span className="flex-1 ml-3 whitespace-nowrap">Settings</span>
                    </Link>
                  </li>
                  <li>
                  <Link className="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group" to="/manage-subscription">
                      <CreditCardIcon
                        className="w-6 h-6 text-gray-700 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                      />
                      <span className="flex-1 ml-3 whitespace-nowrap">
                        Subscription
                      </span>
                    </Link>
                  </li>
                  <li>
                  <Link className="flex items-center p-2 text-base font-medium text-red-600 rounded-lg dark:text-red-500 hover:bg-red-100 dark:hover:bg-gray-700 group" to="/">
                      <svg
                        className="flex-shrink-0 w-6 h-6 text-red-600 transition duration-75 dark:text-red-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                      >
                        <path
                          clipRule="evenodd"
                          fillRule="evenodd"
                          d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                        />
                      </svg>
                      <span className="flex-1 ml-3 whitespace-nowrap">Log out</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </aside>
          </div> 

          {/* <main className="flex-1 pb-4 h-full overflow-y-auto lg:pl-4">
              <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 mb-4">
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
              </div>
              <div className="grid grid-cols-3 gap-4 mb-4">
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
              </div>
            </main> */}


        {/* </div> */}

      {/* </div> */}
    </>
  )
}