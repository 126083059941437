/* eslint-disable jsx-a11y/anchor-is-valid */
import "svgmap/dist/svgMap.min.css";
import Sidebar from "../../components/dashboard/sidebar.jsx";
import Chart from "react-apexcharts";
import NoUserLinks from "../../components/dashboard/NoUserLinks.jsx";
import { Badge, Dropdown, Table, useTheme } from "flowbite-react";
import Navbar from "../../components/dashboard/navbar.jsx"

import { useState } from 'react'
import { Dialog, Switch } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/20/solid'
import {
  BellIcon,
  CreditCardIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import FlowbiteFix from "../../components/dashboard/flowbitefix.jsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ProfileSettingsPage = function () {
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true)
    return (
      <>
      <FlowbiteFix/>
        <div className="bg-gray-50 dark:bg-gray-900 antialised">
        <Navbar />
        <div className="mx-auto flex w-full h-full relative pt-4 xl:pt-8 px-4">
          <Sidebar />
          <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20 max-w-[720px] mx-auto">
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                  This information is private and is not displayed publicly anywhere in the platform.
                </p>

                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">Tom Cook</div>
                      <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                        Update
                      </button>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">tom.cook@example.com</div>
                      <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                        Update
                      </button>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Title</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">Human Resources Manager</div>
                      <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                        Update
                      </button>
                    </dd>
                  </div>
                </dl>
              </div>

              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Bank accounts</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">Connect bank accounts to your account.</p>

                <ul role="list" className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  <li className="flex justify-between gap-x-6 py-6">
                    <div className="font-medium text-gray-900">TD Canada Trust</div>
                    <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Update
                    </button>
                  </li>
                  <li className="flex justify-between gap-x-6 py-6">
                    <div className="font-medium text-gray-900">Royal Bank of Canada</div>
                    <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Update
                    </button>
                  </li>
                </ul>

                <div className="flex border-t border-gray-100 pt-6">
                  <button type="button" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                    <span aria-hidden="true">+</span> Add another bank
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
   
        </div>
      </>
    );
};

export default ProfileSettingsPage;