/* eslint-disable jsx-a11y/anchor-is-valid */
import "svgmap/dist/svgMap.min.css";
import Sidebar from "../../components/dashboard/sidebar.jsx";
import { Badge, Dropdown, Table, useTheme } from "flowbite-react";
import Navbar from "../../components/dashboard/navbar.jsx";
import { Datepicker } from 'flowbite-react';
import {
  PlusIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/outline'
import FlowbiteFix from "../../components/dashboard/flowbitefix.jsx";

const ReportingPage = function () {
    return (
      <>
      <FlowbiteFix/>
        <div className="bg-gray-50 dark:bg-gray-900 antialised">
          <Navbar />
          <div className="mx-auto flex w-full h-full relative pt-4 xl:pt-8 px-4">
            <Sidebar />

            <div className="py-8 px-4 mx-auto lg:py-16">
              <section className="flex items-center bg-gray-50 dark:bg-gray-900">
                  <div className="w-full max-w-screen-xl px-4 mx-auto lg:px-12">
                    <div className="relative sm:min-h-[660px] sm:max-h-[662px] md:min-h-[510px] md:max-h-[660px] md:max-w-[750px] overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
                      <div className="px-4 divide-y dark:divide-gray-700">
                        <div className="flex items-center justify-between py-3">
                          <div className="flex items-center flex-1 space-x-2">
                            <h5 className="font-semibold dark:text-white">Generate Report</h5>
                          </div>
                          <div className="flex items-center space-x-2 md:space-x-4">
                            <button
                              type="button"
                              className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                            >
                              <svg
                                className="w-4 h-4 mr-2"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                                />
                              </svg>
                              Export
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-col items-stretch py-4 md:flex-row md:items-center">
                          <div className="flex flex-col space-y-3 md:flex-row md:items-center md:w-2/3 md:space-y-0">
                            <div
                              className="flex flex-col space-y-1 md:space-y-0 md:flex-row md:items-center md:mr-5"
                            >
                              <div className="relative min-w-[12rem]">
                                <Datepicker />
                              </div>
                              <span className="mx-auto text-gray-500 md:mx-4">to</span>
                              <div className="relative min-w-[12rem]">
                                <Datepicker />  
                              </div>
                            </div>
                            <div className="md:mr-4 min-w-[10rem]">
                              <select
                                defaultValue="pdf"
                                id="compare"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                              >
                                <option value="pdf">PDF</option>
                              </select>
                            </div>
                            <button
                              type="button"
                              className="flex items-center justify-center flex-shrink-0 w-full px-4 py-2 text-sm font-medium text-white rounded-lg md:w-auto bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                            >
                              Generate
                            </button>
                          </div>
                        </div>
                        

                        <div className="text-center">
                          <svg className="mx-auto mt-10 h-12 w-12 text-gray-400" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#00000#9CA3AF0"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24" fill="white"></rect> <path d="M13 3L16 6L19 9M13 3L5 3L5 21L19 21L19 9M13 3L13 9L19 9" stroke="#9CA3AF" strokeLinejoin="round"></path> </g></svg>
                          <h3 className="mt-4 text-2xl font-semibold text-gray-900">Downloadable Insights For Your Campaigns</h3>
                          <p className="mt-4 text-lg text-gray-500">Track click rates, and monitor overall engagement levels. Our process involves compiling data on various metrics to provide you with an all in one stop to pull data from.</p>
                          <button
                            disabled={true}
                            type="button"
                            className="my-6 inline-flex items-center rounded-md bg-primary-700 hover:bg-primary-800 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            <ArrowDownTrayIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                            Download
                          </button>
                        </div>


                      </div>
                    </div>
                  </div>
                </section>
            </div>



          </div>
        </div>
      </>
    );
};

export default ReportingPage;