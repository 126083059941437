/* eslint-disable jsx-a11y/anchor-is-valid */
import "svgmap/dist/svgMap.min.css";
import Sidebar from "../../components/dashboard/sidebar";
import Chart from "react-apexcharts";
import NoUserLinks from "../../components/dashboard/NoUserLinks";
import { Badge, Dropdown, Table, useTheme } from "flowbite-react";
import Navbar from "../../components/dashboard/navbar.jsx"
import { Link } from "react-router-dom";
import FlowbiteFix from "../../components/dashboard/flowbitefix.jsx";

const stats = [
  { name: 'Links', stat: '5 / 10' },
  { name: 'QR Codes', stat: '3 / 100' },
  { name: 'Reports', stat: '0 / 50' },
]

const posts = [
  {
    title: 'Boost your conversion rate',
    href: '#',
    category: { name: 'Article', href: '#', color: 'bg-indigo-100 text-indigo-800' },
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    author: {
      name: 'Paul York',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    readingTime: '6 min',
  },
  {
    title: 'Boost your conversion rate',
    href: '#',
    category: { name: 'Video', href: '#', color: 'bg-pink-100 text-pink-800' },
    description:
      'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'Mar 10, 2020',
    datetime: '2020-03-10',
    author: {
      name: 'Dessie Ryan',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    readingTime: '4 min',
  },
  {
    title: 'Boost your conversion rate',
    href: '#',
    category: { name: 'Case Study', href: '#', color: 'bg-green-100 text-green-800' },
    description: 'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    date: 'Feb 12, 2020',
    datetime: '2020-02-12',
    author: {
      name: 'Easer Collins',
      href: '#',
      imageUrl:
        'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    readingTime: '11 min',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const HomePage = function () {
    return (
      <>
      <FlowbiteFix/>
        <div className="bg-gray-50 dark:bg-gray-900 antialised">
        <Navbar />
        <div className="mx-auto flex w-full h-full relative pt-4 xl:pt-8 px-4">
          <Sidebar />
          {/* <main className="flex-1 pb-4 h-full overflow-y-auto lg:pl-4">
            <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 mb-4">
              <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64">Homepage...</div>
              <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
              <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl"></div>
              <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
            </div>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
              </div>
              <div className="grid grid-cols-3 gap-4 mb-4">
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-32 lg:h-64"></div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
                <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-xl h-48 lg:h-96"></div>
              </div>
          </main> */}



          <div className="bg-gray-50 pt-2 sm:pt-2 mx-auto">


          {/* <div>
            <dl className="mt-1 grid grid-cols-1 gap-5 sm:grid-cols-3">
              {stats.map((item) => (
                <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                </div>
              ))}
            </dl>
          </div> */}
                


                {/* <div className="bg-gray-50 px-6 pb-20 pt-1 lg:px-8 lg:pb-28 lg:pt-1">
                  <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
                    <div>
                      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Recent publications</h2>
                      <p className="mt-3 text-xl text-gray-500 sm:mt-4">
                        Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus
                        arcu.
                      </p>
                    </div>
                    <div className="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
                      {posts.map((post) => (
                        <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-4" key={post.title}>
                          <div>
                            <a href={post.category.href} className="inline-block">
                              <span
                                className={classNames(
                                  post.category.color,
                                  'inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium'
                                )}
                              >
                                {post.category.name}
                              </span>
                            </a>
                          </div>
                          <a href={post.href} className="mt-4 block">
                            <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                            <p className="mt-3 text-base text-gray-500">{post.description}</p>
                          </a>
                          <div className="mt-6 flex items-center">
                            <div className="flex-shrink-0">
                              <a href={post.author.href}>
                                <span className="sr-only">{post.author.name}</span>
                                <img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt="" />
                              </a>
                            </div>
                            <div className="ml-3">
                              <p className="text-sm font-medium text-gray-900">
                                <a href={post.author.href}>{post.author.name}</a>
                              </p>
                              <div className="flex space-x-1 text-sm text-gray-500">
                                <time dateTime={post.datetime}>{post.date}</time>
                                <span aria-hidden="true">&middot;</span>
                                <span>{post.readingTime} read</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div> */}
                
          </div>



              




        </div>
         
        </div>
      </>
    );
};

const SalesThisWeek = function () {
  return (<div className="rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 xl:p-8">
    <div className="mb-4 flex items-center justify-between">
      <div className="shrink-0">
        <span className="text-2xl font-bold leading-none text-gray-900 dark:text-white sm:text-3xl">
          $45,385
        </span>
        <h3 className="text-base font-normal text-gray-600 dark:text-gray-400">
          Sales this week
        </h3>
      </div>
      <div className="flex flex-1 items-center justify-end text-base font-bold text-green-600 dark:text-green-400">
        12.5%
        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clipRule="evenodd"/>
        </svg>
      </div>
    </div>
    <SalesChart />
    <div className="mt-5 flex items-center justify-between border-t border-gray-200 pt-3 dark:border-gray-700 sm:pt-6">
      <Datepicker />
      <div className="shrink-0">
        <a href="#" className="inline-flex items-center rounded-lg p-2 text-xs font-medium uppercase text-primary-700 hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700 sm:text-sm">
          Sales Report
          <svg className="ml-1 h-4 w-4 sm:h-5 sm:w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
          </svg>
        </a>
      </div>
    </div>
  </div>);
};
const SalesChart = function () {
  const { mode } = useTheme();
  const isDarkTheme = mode === "dark";
  const borderColor = isDarkTheme ? "#374151" : "#F3F4F6";
  const labelColor = isDarkTheme ? "#93ACAF" : "#6B7280";
  const opacityFrom = isDarkTheme ? 0 : 0.45;
  const opacityTo = isDarkTheme ? 0.15 : 0;
  const options = {
      stroke: {
          curve: "smooth",
      },
      chart: {
          type: "area",
          fontFamily: "Inter, sans-serif",
          foreColor: labelColor,
          toolbar: {
              show: false,
          },
      },
      fill: {
          type: "gradient",
          gradient: {
              opacityFrom,
              opacityTo,
              type: "vertical",
          },
      },
      dataLabels: {
          enabled: false,
      },
      tooltip: {
          style: {
              fontSize: "14px",
              fontFamily: "Inter, sans-serif",
          },
      },
      grid: {
          show: true,
          borderColor: borderColor,
          strokeDashArray: 1,
          padding: {
              left: 35,
              bottom: 15,
          },
      },
      markers: {
          size: 5,
          strokeColors: "#ffffff",
          hover: {
              size: undefined,
              sizeOffset: 3,
          },
      },
      xaxis: {
          categories: [
              "01 Feb",
              "02 Feb",
              "03 Feb",
              "04 Feb",
              "05 Feb",
              "06 Feb",
              "07 Feb",
          ],
          labels: {
              style: {
                  colors: [labelColor],
                  fontSize: "14px",
                  fontWeight: 500,
              },
          },
          axisBorder: {
              color: borderColor,
          },
          axisTicks: {
              color: borderColor,
          },
          crosshairs: {
              show: true,
              position: "back",
              stroke: {
                  color: borderColor,
                  width: 1,
                  dashArray: 10,
              },
          },
      },
      yaxis: {
          labels: {
              style: {
                  colors: [labelColor],
                  fontSize: "14px",
                  fontWeight: 500,
              },
              formatter: function (value) {
                  return "$" + value;
              },
          },
      },
      legend: {
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "Inter, sans-serif",
          labels: {
              colors: [labelColor],
          },
          itemMargin: {
              horizontal: 10,
          },
      },
      responsive: [
          {
              breakpoint: 1024,
              options: {
                  xaxis: {
                      labels: {
                          show: false,
                      },
                  },
              },
          },
      ],
  };
  const series = [
      {
          name: "Revenue",
          data: [6356, 6218, 6156, 6526, 6356, 6256, 6056],
          color: "#1A56DB",
      },
      {
          name: "Revenue (previous period)",
          data: [6556, 6725, 6424, 6356, 6586, 6756, 6616],
          color: "#FDBA8C",
      },
  ];
  return <Chart height={420} options={options} series={series} type="area"/>;
};
const Datepicker = function () {
  return (<span className="text-sm text-gray-600">
    <Dropdown inline label="Last 7 days">
      <Dropdown.Item>
        <strong>Sep 16, 2021 - Sep 22, 2021</strong>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item>Yesterday</Dropdown.Item>
      <Dropdown.Item>Today</Dropdown.Item>
      <Dropdown.Item>Last 7 days</Dropdown.Item>
      <Dropdown.Item>Last 30 days</Dropdown.Item>
      <Dropdown.Item>Last 90 days</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item>Custom...</Dropdown.Item>
    </Dropdown>
  </span>);
};

export default HomePage;