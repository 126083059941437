import { Fragment, useState, useEffect, useRef } from "react";
import {
  PlusIcon,
  ExclamationTriangleIcon,
  CheckIcon,
  ClipboardIcon,
  CursorArrowRaysIcon,
  QrCodeIcon,
  DocumentDuplicateIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import FlowbiteFix from "../components/dashboard/flowbitefix";
import LandingHeader from "../components/landing/Header";
import axios from "axios";
import LandingFAQ from "../components/landing/FAQ";
import toast, { Toaster } from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";

import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import { RadioGroup } from "@headlessui/react";

let responseUrl = "";
let destinationUrl = "";
let currFormat = "svg";
let isSVG = true;

const sizeOptions = [
  { name: "128", size: 128, clickable: true },
  { name: "256", size: 256, clickable: true },
  { name: "512", size: 512, clickable: true },
  { name: "1024", size: 1024, clickable: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function isValidUrl(str) {
  const pattern = new RegExp(
    "^([a-zA-Z]+:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  return pattern.test(str);
}

function returnUrlWithProtocol(str) {
  if (str.indexOf("http://") === 0 || str.indexOf("https://") === 0) {
    return str;
  }
  return `http://${str}`;
}

const headers = {
  "Content-Type": "application/json",
};

const LandingPage = function () {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openQR, setOpenQR] = useState(false);
  const [svg, setSVG] = useState(true);
  const [showLink, setShowLink] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [copyValue, setCopyValue] = useState("");
  const [copied, setCopied] = useState(false);
  const [destination, setDestination] = useState("");
  const [format, setFormat] = useState("");
  const [size, setSize] = useState(sizeOptions[0]);
  const qrCodeRef = useRef(null);

  const downloadQRCode = (format) => {
    if (format === true) {
      htmlToImage
        .toSvg(qrCodeRef.current, { width: size.size, height: size.size })
        .then(function (dataUrl) {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "itstinylink-qr-" + size.size + ".svg";
          link.click();
        })
        .catch(function (error) {
          console.error("Error generating QR code:", error);
        });
    }
    if (format === false) {
      htmlToImage
        .toPng(qrCodeRef.current, { width: size.size, height: size.size })
        .then(function (dataUrl) {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "itstinylink-qr-" + size.size + ".png";
          link.click();
        })
        .catch(function (error) {
          console.error("Error generating QR code:", error);
        });
    }
  };

  function detectFormat(e) {
    if (e.target.value === "svg") {
      isSVG = true;
    }
    if (e.target.value === "png") {
      isSVG = false;
    }
  }

  function resetFormat() {
    if (currFormat !== "svg") currFormat = "svg";
  }

  const onCopy = () => {
    setCopied(true);
    toast.success("Copied to clipboard");
  };

  const handleChange = (event) => {
    setDestination(event.target.value);
  };

  const handleClick = (e, destination) => {
    // e.preventDefault();
    if (!isValidUrl(destination)) {
      setIsValid(false);
      return;
    }

    //temporarily prepend protocol until backend fixes this
    destination = returnUrlWithProtocol(destination);
    destinationUrl = destination;

    let postData = {
      customer_name: "default",
      url_item_count: 1,
      url: destination,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    setIsWaiting(true);
    axios
      .post("https://api.itstiny.link/api/urls", postData, axiosConfig)
      .then(function (response) {
        if (response.status === 200) {
          //success
          responseUrl = response.data.short_url;
          setOpenSuccess(true);
          setShowLink(true);
        } else {
          //error
          setOpenError(true);
        }
        setIsWaiting(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsWaiting(false);
      });
    //clean outputs after submit
    setDestination("");
    setIsValid(true);
    //reset format back to SVG as default
    resetFormat();
  };
  return (
    <>
      <FlowbiteFix />
      <Toaster />
      <LandingHeader />
      <section className="bg-gray-50 dark:bg-gray-900 h-auto">
        <div className="text-center">
          <h1 className="mt-8 lg:mt-16 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            Tiny your URL with tinylink
          </h1>
          <p className="max-w-3xl mx-auto mt-4 text-gray-500 md:text-lg lg:text-xl dark:text-gray-400">
            Create a smaller, visually appealing link to funnel your traffic
            through
          </p>
        </div>

        <div className="px-1 pt-6 align-center text-center">
          {!isValid ? (
            <>
              <label
                htmlFor="name"
                className=" text-md font-medium text-red-500 dark:text-white"
              >
                Please enter a valid URL
              </label>
            </>
          ) : null}
        </div>

        <div className="px-4 py-8 align-center text-center">
          <input
            type="text"
            name="name"
            id="name"
            onChange={handleChange}
            className="min-h-16 max-h-16 xs:min-w-72 ks:min-w-72 md:min-w-96 min-w-56 bg-gray-50 border border-gray-300 text-gray-900 text-lg focus:ring-primary-600 focus:border-primary-600 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            placeholder="Enter link to make tiny"
            value={destination}
            required={true}
          />
          <button
            onClick={(e) => handleClick(e, destination)}
            className="min-h-16 max-h-16 xs:min-w-72 ks:min-w-72 md:min-w-36 min-w-56 p-6 py-3.5 text-base font-medium text-center focus:hover-2 rounded-md text-white bg-primary-700 hover:bg-primary-800 focus:ring-1 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-900 disabled:bg-blue-400 sm:ml-1 md:ml-1 lg:ml-1 xl:ml-1"
            disabled={isWaiting}
          >
            {isWaiting ? (
              <>
                <svg
                  className="animate-spin h-5 w-5 text-white mx-auto text-center align-center justify-center"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx={12}
                    cy={12}
                    r={10}
                    stroke="currentColor"
                    strokeWidth={4}
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              </>
            ) : (
              <>
                <span className="ml-2">GO</span>
              </>
            )}
          </button>
        </div>

        {showLink ? (
          <>
            <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-12 xl:gap-0 lg:py-16 sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12">
              <div className="xs:col-span-4 sm:col-span-12 md:col-span-2 lg:col-span-2 xl:col-span-2"></div>
              <div className="xs:col-span-4 sm:col-span-12 md:col-span-8 lg:col-span-8 xl:col-span-8 flex-col">
                <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                  <div className="flow-root">
                    <h3 className="text-xl font-semibold dark:text-white">
                      Your link is ready
                    </h3>
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                      <li className="py-4">
                        <div className="flex items-center space-x-4">
                          <div className="flex-shrink-0">
                            <CopyToClipboard onCopy={onCopy} text={responseUrl}>
                              <button>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                                  />
                                </svg>
                              </button>
                            </CopyToClipboard>
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-base font-semibold text-gray-900 truncate max-w-60 xl:max-w-md lg:max-w-md md:max-w-md sm:max-w-md dark:text-white">
                              {destinationUrl}
                            </p>
                            <p className="text-md text-blue-600 font-normal truncate dark:text-gray-400">
                              <a
                                className="inline"
                                href={responseUrl}
                                target="_blank"
                              >
                                {responseUrl}
                              </a>
                            </p>
                          </div>
                          <div className="inline-flex items-center">
                            <button
                              onClick={() => setOpenQR(true)}
                              className="xs:hidden sm:inline-flex md:inline-flex lg:inline-flex xl:inline-flex mx-2 inline-flex px-5 py-2.5 text-sm font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800 disabled:bg-blue-400"
                            >
                              <QrCodeIcon
                                className="-ml-0.5 h-5 w-5"
                                aria-hidden="true"
                              />
                              <span className="ml-2">QR</span>
                            </button>
                            <CopyToClipboard onCopy={onCopy} text={responseUrl}>
                              <button className="xs:hidden sm:inline-flex md:inline-flex lg:inline-flex xl:inline-flex inline-flex px-5 py-2.5 text-sm font-medium text-center text-white bg-green-600 rounded-lg focus:ring-4 focus:ring-green-200 dark:focus:ring-primary-900 hover:bg-green-700">
                                <DocumentDuplicateIcon
                                  className="-ml-0.5 h-5 w-5"
                                  aria-hidden="true"
                                />
                                <span className="ml-2">COPY</span>
                              </button>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div>
                      <button
                        onClick={() => setOpenQR(true)}
                        className="xs:inline-flex sm:hidden md:hidden lg:hidden xl:hidden mx-2 inline-flex px-5 py-2.5 text-sm font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800 disabled:bg-blue-400"
                      >
                        <QrCodeIcon
                          className="-ml-0.5 h-5 w-5"
                          aria-hidden="true"
                        />
                        <span className="ml-2">QR</span>
                      </button>
                      <CopyToClipboard onCopy={onCopy} text={responseUrl}>
                        <button className="xs:inline-flex sm:hidden md:hidden lg:hidden xl:hidden inline-flex px-5 py-2.5 text-sm font-medium text-center text-white bg-green-600 rounded-lg focus:ring-4 focus:ring-green-200 dark:focus:ring-primary-900 hover:bg-green-700">
                          <ClipboardIcon
                            className="-ml-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                          <span className="ml-2">COPY</span>
                        </button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>
              <div className="xs:col-span-1 sm:col-span-12 md:col-span-2 lg:col-span-2 xl:col-span-2"></div>
            </div>
          </>
        ) : null}

        {openQR ? (
          <>
            <Transition.Root show={openQR} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={setOpenQR}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                          <button
                            onClick={() => {
                              setOpenQR(false);
                            }}
                            type="button"
                            className="absolute top-1 end-1 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="popup-modal"
                          >
                            <svg
                              className="w-3 h-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                            <span className="sr-only">Close modal</span>
                          </button>

                          <div className="mx-auto mt-3 flex h-36 w-36 items-center justify-center rounded-full">
                            <QRCode
                              value={responseUrl}
                              size={128}
                              ref={qrCodeRef}
                            />
                          </div>
                          {/* <div className="sm:mt-5 mt-3 text-center">
                            <div className="mb-2">
                              <p className="text-md text-gray-500"></p>
                              <p className="text-md text-blue-500">
                                <a
                                  className="inline"
                                  href={responseUrl}
                                  target="_blank"
                                >
                                  {responseUrl}
                                </a>
                              </p>
                            </div>
                          </div> */}
                          <div
                            className="mt-3 flex mx-auto justify-center"
                            onChange={(e) => detectFormat(e)}
                          >
                            <div className="flex items-center me-4">
                              <div className="flex items-center">
                                <input
                                  defaultChecked={isSVG}
                                  id="svg"
                                  type="radio"
                                  defaultValue="svg"
                                  name="format"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor="svg"
                                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  SVG
                                </label>
                              </div>
                            </div>
                            <div className="flex items-center me-4">
                              <input
                                defaultChecked={!isSVG}
                                id="png"
                                type="radio"
                                defaultValue="png"
                                name="format"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="png"
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                PNG
                              </label>
                            </div>
                          </div>
                          <div className="">
                            <h2 className="mt-2 text-sm font-medium leading-6 text-gray-900 xs:text-center">
                              Size
                            </h2>
                          </div>
                          <RadioGroup
                            value={size}
                            onChange={setSize}
                            className="mt-2"
                          >
                            <RadioGroup.Label className="sr-only">
                              Choose a size
                            </RadioGroup.Label>
                            <div className="grid grid-cols-3 gap-3 xs:grid-cols-2 ks:grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
                              {sizeOptions.map((option) => (
                                <RadioGroup.Option
                                  key={option.name}
                                  value={option}
                                  className={({ active, checked }) =>
                                    classNames(
                                      option.clickable
                                        ? "cursor-pointer focus:outline-none"
                                        : "cursor-not-allowed opacity-25",
                                      active
                                        ? "cursor-pointer ring-0 ring-indigo-600 ring-offset-1"
                                        : "cursor-pointer",
                                      checked
                                        ? "cursor-pointer bg-primary-600 text-white hover:bg-primary-500"
                                        : "ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50",
                                      "flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1"
                                    )
                                  }
                                >
                                  <RadioGroup.Label as="span">
                                    {option.name}
                                  </RadioGroup.Label>
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                        </div>
                        <div className="grid grid-cols-4 gap-1 mt-5 sm:mt-6">
                          <button
                            onClick={() => downloadQRCode(isSVG)}
                            type="button"
                            className="col-span-2 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-primary-700 hover:bg-primary-800 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-900"
                          >
                            <ArrowDownTrayIcon
                              className="-ml-0.5 h-5 w-5"
                              aria-hidden="true"
                            />
                            <span className="ml-2">Download</span>
                          </button>
                          <button
                            type="button"
                            className="col-span-2 inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => setOpenQR(false)}
                          >
                            Continue
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </>
        ) : null}

        {/* {openSuccess ? (
          <>
            <Transition.Root show={openSuccess} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={setOpenSuccess}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                          <button
                            onClick={() => {
                              setOpenSuccess(false);
                            }}
                            type="button"
                            className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="popup-modal"
                          >
                            <svg
                              className="w-3 h-3"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 14"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                              />
                            </svg>
                            <span className="sr-only">Close modal</span>
                          </button>
                          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                            <CheckIcon
                              className="h-6 w-6 text-green-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900"
                            >
                              Link Created
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-md text-gray-500">
                              </p>
                              <p className="text-md text-blue-500">
                                <a
                                  className="inline"
                                  href={responseUrl}
                                  target="_blank"
                                >
                                  {responseUrl}
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="grid grid-cols-4 gap-1 mt-5 sm:mt-6">
                          <button
                            type="button"
                            className="col-span-2 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-primary-700 hover:bg-primary-800 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-900"
                            onClick={copyToClipboard}
                          >
                            <ClipboardIcon
                              className="-ml-0.5 h-5 w-5"
                              aria-hidden="true"
                            />
                            <span className="ml-2">Copy</span>
                          </button>
                          <button
                            type="button"
                            className="col-span-2 inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => setOpenSuccess(false)}
                          >
                            Continue
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </>
        ) : null} */}

        {openError ? (
          <>
            <Transition.Root show={openError} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={setOpenError}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                            <ExclamationTriangleIcon
                              className="h-6 w-6 text-red-600"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="mt-3 text-center sm:mt-5">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900"
                            >
                              Error
                            </Dialog.Title>
                            <div className="mt-2">
                              <p className="text-sm text-gray-500">
                                Something went wrong, please try again.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => setOpenError(false)}
                          >
                            OK
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </>
        ) : null}

        <section className="bg-gray-50 dark:bg-gray-900">
          <div className="max-w-screen-xl py-4 lg:py-8 px-4 mx-auto lg:mb-16 lg:px-6 text-center">
            <dl className="grid gap-8 mt-8 text-gray-900 sm:grid-cols-2 lg:gap-20 lg:mt-14 lg:grid-cols-4 dark:text-white">
              <div className="flex flex-col">
                <dt className="mb-2 text-3xl md:text-4xl font-extrabold tracking-tight">
                  100+
                </dt>
                <dd className="font-light text-gray-500 dark:text-gray-400">
                  Active Users
                </dd>
              </div>
              <div className="flex flex-col">
                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
                  10,000+
                </dt>
                <dd className="font-light text-gray-500 dark:text-gray-400">
                  Links Shortened
                </dd>
              </div>
              <div className="flex flex-col">
                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
                  1,000+
                </dt>
                <dd className="font-light text-gray-500 dark:text-gray-400">
                  QR Codes generated
                </dd>
              </div>
              <div className="flex flex-col">
                <dt className="mb-2 text-3xl md:text-4xl font-extrabold">
                  1,000+
                </dt>
                <dd className="font-light text-gray-500 dark:text-gray-400">
                  Reports Generated
                </dd>
              </div>
            </dl>
          </div>
        </section>
      </section>

      <section
        className="border-t border-b border-gray-100 bg-white dark:bg-gray-800 dark:border-gray-700"
        id="features"
      >
        <div className="max-w-screen-xl px-4 py-8 mx-auto sm:py-16 lg:py-24">
          <div className="space-y-12 lg:space-y-20">
            <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
              <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl dark:text-white">
                  Maximize Data
                </h2>
                <p className="mb-8 lg:text-xl">
                  Leverage the power of analytics to your advantage, and quickly
                  learn which campaigns are performing, and which are not.
                </p>
                <ul
                  role="list"
                  className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
                >
                  <li className="flex space-x-3">
                    <div className="inline-flex items-center justify-center w-5 h-5 text-green-600 bg-green-100 rounded-full dark:bg-green-900 dark:text-green-400">
                      <svg
                        className="w-3.5 h-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Link Analytics
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <div className="inline-flex items-center justify-center w-5 h-5 text-green-600 bg-green-100 rounded-full dark:bg-green-900 dark:text-green-400">
                      <svg
                        className="w-3.5 h-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      UTM Parameters
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <div className="inline-flex items-center justify-center w-5 h-5 text-green-600 bg-green-100 rounded-full dark:bg-green-900 dark:text-green-400">
                      <svg
                        className="w-3.5 h-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      PDF Reports
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <div className="inline-flex items-center justify-center w-5 h-5 text-green-600 bg-green-100 rounded-full dark:bg-green-900 dark:text-green-400">
                      <svg
                        className="w-3.5 h-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      CSV Reports
                    </span>
                  </li>
                </ul>
                <p className="mb-8 lg:text-xl">
                  View link analytics, and then package it up in our reporting
                  generator to track progress share with your clients.
                </p>
              </div>
              <div className="hidden mb-4 lg:flex lg:mb-0">
                <img
                  className="w-full border border-gray-100 rounded-lg shadow-md dark:hidden"
                  src={process.env.PUBLIC_URL + "/images/data.jpg"}
                  alt=""
                />
                <img
                  className="hidden w-full border rounded-lg shadow-md dark:border-gray-700 dark:block"
                  src={process.env.PUBLIC_URL + "/images/data.jpg"}
                  alt=""
                />
              </div>
            </div>
            {/* Row */}
            <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
              <div className="hidden mb-4 lg:flex lg:mb-0">
                <img
                  className="w-full border border-gray-100 rounded-lg shadow-md dark:hidden"
                  src={process.env.PUBLIC_URL + "/images/marketing.jpg"}
                  alt=""
                />
                <img
                  className="hidden w-full border rounded-lg shadow-md dark:border-gray-700 dark:block"
                  src={process.env.PUBLIC_URL + "/images/marketing.jpg"}
                  alt=""
                />
              </div>
              <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl dark:text-white">
                  Increase Conversions
                </h2>
                <p className="mb-8 lg:text-xl">
                  Create a tiny link for your marketing campaigns, or even
                  generate QR Codes to make your link scannable and accessible
                  to anybody with a smart phone.
                </p>
                <ul
                  role="list"
                  className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700"
                >
                  <li className="flex space-x-3">
                    <div className="inline-flex items-center justify-center w-5 h-5 text-green-600 bg-green-100 rounded-full dark:bg-green-900 dark:text-green-400">
                      <svg
                        className="w-3.5 h-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Unlimited Link Clicks
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <div className="inline-flex items-center justify-center w-5 h-5 text-green-600 bg-green-100 rounded-full dark:bg-green-900 dark:text-green-400">
                      <svg
                        className="w-3.5 h-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Custom URL Aliases
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <div className="inline-flex items-center justify-center w-5 h-5 text-green-600 bg-green-100 rounded-full dark:bg-green-900 dark:text-green-400">
                      <svg
                        className="w-3.5 h-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      QR Code Generator
                    </span>
                  </li>
                  <li className="flex space-x-3">
                    <div className="inline-flex items-center justify-center w-5 h-5 text-green-600 bg-green-100 rounded-full dark:bg-green-900 dark:text-green-400">
                      <svg
                        className="w-3.5 h-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                      Edit Destination URL
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white dark:bg-gray-900" id="plans">
        <div className="max-w-screen-xl px-4 py-8 mx-auto sm:py-16 lg:py-24">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl dark:text-white">
              Choose the right plan for you
            </h2>
            <p className="mb-5 text-gray-500 sm:text-xl dark:text-gray-400">
              Whether its a personal project, or a large scale marketing
              campaign, we are confident we can exceed your expectations.
            </p>
          </div>
          <div className="grid gap-8 mt-8 mb-8 xl:grid-cols-3 sm:mt-12">
            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 xl:p-8 dark:text-white dark:bg-gray-800">
              <h3 className="mb-4 text-2xl font-semibold">Free</h3>
              <p className="text-gray-500 text-light sm:text-lg dark:text-gray-400">
                Great for a single user with personal projects
              </p>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-5xl font-extrabold">$0</span>
                <span className="text-gray-500">/month</span>
              </div>
              <ul role="list" className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>Up To 25 Links</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>Unlimited Link Clicks</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>QR Code Generator</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="line-through">Link Analytics</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="line-through">Report Generator</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="line-through">Help & Support</span>
                </li>
              </ul>
              <a
                href="#"
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-900"
              >
                Get started
              </a>
            </div>
            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 xl:p-8 dark:text-white dark:bg-gray-800">
              <h3 className="mb-4 text-2xl font-semibold">Standard</h3>
              <p className="text-gray-500 text-light sm:text-lg dark:text-gray-400">
                For basic analytics <br />
                *Includes everything in the previous tier
              </p>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-5xl font-extrabold">$1.49</span>
                <span className="text-gray-500">/month</span>
              </div>
              <ul role="list" className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>Up To 100 Links</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>UTM Parameter Builder</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>Edit Destination URL</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>Link Analytics</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="line-through">Report Generator</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span className="line-through">Help & Support</span>
                </li>
              </ul>
              <a
                href="#"
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-900"
              >
                Get started
              </a>
            </div>
            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 xl:p-8 dark:text-white dark:bg-gray-800">
              <h3 className="mb-4 text-2xl font-semibold">Premium</h3>
              <p className="text-gray-500 text-light sm:text-lg dark:text-gray-400">
                For the data enthusiasts <br />
                *Includes everything in the previous tier
              </p>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-5xl font-extrabold">$3.99</span>
                <span className="text-gray-500">/month</span>
              </div>
              <ul role="list" className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>Up To 250 Links</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>Geo Targeting</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>Link-in-Bio</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>Link Tagging</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>Report Generator</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-4 h-4 text-green-400 dark:text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                  <span>Help & Support</span>
                </li>
              </ul>
              <a
                href="#"
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-900"
              >
                Get started
              </a>
            </div>
          </div>
        </div>
      </section>
      <LandingFAQ />
      <footer className="p-4 bg-gray-50 md:p-8 lg:p-10 dark:bg-gray-800">
        <div className="mx-auto max-w-screen-xl text-center">
          <a
            href="#"
            className="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <svg
              className="mr-2 h-8"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.2696 13.126C25.1955 13.6364 24.8589 14.3299 24.4728 14.9328C23.9856 15.6936 23.2125 16.2264 22.3276 16.4114L18.43 17.2265C17.8035 17.3575 17.2355 17.6853 16.8089 18.1621L14.2533 21.0188C13.773 21.5556 13.4373 21.4276 13.4373 20.7075C13.4315 20.7342 12.1689 23.9903 15.5149 25.9202C16.8005 26.6618 18.6511 26.3953 19.9367 25.6538L26.7486 21.7247C29.2961 20.2553 31.0948 17.7695 31.6926 14.892C31.7163 14.7781 31.7345 14.6639 31.7542 14.5498L25.2696 13.126Z"
                fill="url(#paint0_linear_11430_22515)"
              />
              <path
                d="M23.5028 9.20133C24.7884 9.94288 25.3137 11.0469 25.3137 12.53C25.3137 12.7313 25.2979 12.9302 25.2694 13.1261L28.0141 14.3051L31.754 14.5499C32.2329 11.7784 31.2944 8.92561 29.612 6.65804C28.3459 4.9516 26.7167 3.47073 24.7581 2.34097C23.167 1.42325 21.5136 0.818599 19.8525 0.486816L17.9861 2.90382L17.3965 5.67918L23.5028 9.20133Z"
                fill="url(#paint1_linear_11430_22515)"
              />
              <path
                d="M1.5336 11.2352C1.5329 11.2373 1.53483 11.238 1.53556 11.2358C1.67958 10.8038 1.86018 10.3219 2.08564 9.80704C3.26334 7.11765 5.53286 5.32397 8.32492 4.40943C11.117 3.49491 14.1655 3.81547 16.7101 5.28323L17.3965 5.67913L19.8525 0.486761C12.041 -1.07341 4.05728 3.51588 1.54353 11.2051C1.54233 11.2087 1.53796 11.2216 1.5336 11.2352Z"
                fill="url(#paint2_linear_11430_22515)"
              />
              <path
                d="M19.6699 25.6538C18.3843 26.3953 16.8003 26.3953 15.5147 25.6538C15.3402 25.5531 15.1757 25.4399 15.0201 25.3174L12.7591 26.8719L10.8103 30.0209C12.9733 31.821 15.7821 32.3997 18.589 32.0779C20.7013 31.8357 22.7995 31.1665 24.7582 30.0368C26.3492 29.1191 27.7 27.9909 28.8182 26.7195L27.6563 23.8962L25.7762 22.1316L19.6699 25.6538Z"
                fill="url(#paint3_linear_11430_22515)"
              />
              <path
                d="M15.0201 25.3175C14.0296 24.5373 13.4371 23.3406 13.4371 22.0588V21.931V11.2558C13.4371 10.6521 13.615 10.5494 14.1384 10.8513C13.3323 10.3864 11.4703 8.79036 9.17118 10.1165C7.88557 10.858 6.8269 12.4949 6.8269 13.978V21.8362C6.8269 24.775 8.34906 27.8406 10.5445 29.7966C10.6313 29.874 10.7212 29.9469 10.8103 30.0211L15.0201 25.3175Z"
                fill="url(#paint4_linear_11430_22515)"
              />
              <path
                d="M28.6604 5.49565C28.6589 5.49395 28.6573 5.49532 28.6589 5.49703C28.9613 5.83763 29.2888 6.23485 29.6223 6.68734C31.3648 9.05099 32.0158 12.0447 31.4126 14.9176C30.8093 17.7906 29.0071 20.2679 26.4625 21.7357L25.7761 22.1316L28.8181 26.7195C34.0764 20.741 34.09 11.5388 28.6815 5.51929C28.6789 5.51641 28.67 5.50622 28.6604 5.49565Z"
                fill="url(#paint5_linear_11430_22515)"
              />
              <path
                d="M7.09355 13.978C7.09354 12.4949 7.88551 11.1244 9.17113 10.3829C9.34564 10.2822 9.52601 10.1965 9.71002 10.1231L9.49304 7.38962L7.96861 4.26221C5.32671 5.23364 3.1897 7.24125 2.06528 9.83067C1.2191 11.7793 0.75001 13.9294 0.75 16.1888C0.75 18.0243 1.05255 19.7571 1.59553 21.3603L4.62391 21.7666L7.09355 21.0223V13.978Z"
                fill="url(#paint6_linear_11430_22515)"
              />
              <path
                d="M9.71016 10.1231C10.8817 9.65623 12.2153 9.74199 13.3264 10.3829L13.4372 10.4468L22.3326 15.5777C22.9566 15.9376 22.8999 16.2918 22.1946 16.4392L22.7078 16.332C23.383 16.1908 23.9999 15.8457 24.4717 15.3428C25.2828 14.4782 25.5806 13.4351 25.5806 12.5299C25.5806 11.0468 24.7886 9.67634 23.503 8.93479L16.6911 5.00568C14.1436 3.53627 11.0895 3.22294 8.29622 4.14442C8.18572 4.18087 8.07756 4.2222 7.96875 4.26221L9.71016 10.1231Z"
                fill="url(#paint7_linear_11430_22515)"
              />
              <path
                d="M20.0721 31.8357C20.0744 31.8352 20.0739 31.8332 20.0717 31.8337C19.6252 31.925 19.1172 32.0097 18.5581 32.0721C15.638 32.3978 12.7174 31.4643 10.5286 29.5059C8.33986 27.5474 7.09347 24.7495 7.09348 21.814L7.09347 21.0222L1.59546 21.3602C4.1488 28.8989 12.1189 33.5118 20.0411 31.8421C20.0449 31.8413 20.0582 31.8387 20.0721 31.8357Z"
                fill="url(#paint8_linear_11430_22515)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_11430_22515"
                  x1="20.8102"
                  y1="23.9532"
                  x2="23.9577"
                  y2="12.9901"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#1724C9" />
                  <stop offset={1} stopColor="#1C64F2" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_11430_22515"
                  x1="28.0593"
                  y1="10.5837"
                  x2="19.7797"
                  y2="2.33321"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#1C64F2" />
                  <stop offset={1} stopColor="#0092FF" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_11430_22515"
                  x1="16.9145"
                  y1="5.2045"
                  x2="4.42432"
                  y2="5.99375"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#0092FF" />
                  <stop offset={1} stopColor="#45B2FF" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_11430_22515"
                  x1="16.0698"
                  y1="28.846"
                  x2="27.2866"
                  y2="25.8192"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#1C64F2" />
                  <stop offset={1} stopColor="#0092FF" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_11430_22515"
                  x1="8.01881"
                  y1="15.8661"
                  x2="15.9825"
                  y2="24.1181"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#1724C9" />
                  <stop offset={1} stopColor="#1C64F2" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_11430_22515"
                  x1="26.2004"
                  y1="21.8189"
                  x2="31.7569"
                  y2="10.6178"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#0092FF" />
                  <stop offset={1} stopColor="#45B2FF" />
                </linearGradient>
                <linearGradient
                  id="paint6_linear_11430_22515"
                  x1="6.11387"
                  y1="9.31427"
                  x2="3.14054"
                  y2="20.4898"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#1C64F2" />
                  <stop offset={1} stopColor="#0092FF" />
                </linearGradient>
                <linearGradient
                  id="paint7_linear_11430_22515"
                  x1="21.2932"
                  y1="8.78271"
                  x2="10.4278"
                  y2="11.488"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#1724C9" />
                  <stop offset={1} stopColor="#1C64F2" />
                </linearGradient>
                <linearGradient
                  id="paint8_linear_11430_22515"
                  x1="7.15667"
                  y1="21.5399"
                  x2="14.0824"
                  y2="31.9579"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#0092FF" />
                  <stop offset={1} stopColor="#45B2FF" />
                </linearGradient>
              </defs>
            </svg>
            itstiny.link
          </a>
          <p className="my-6 text-gray-500 dark:text-gray-400">
            Your all in one URL shortening service
          </p>
          <ul className="flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white">
            <li>
              <a href="#features" className="mr-4 hover:underline md:mr-6 ">
                Features
              </a>
            </li>
            <li>
              <a href="#plans" className="mr-4 hover:underline md:mr-6">
                Plans
              </a>
            </li>
            <li>
              <a href="#faq" className="mr-4 hover:underline md:mr-6 ">
                FAQ
              </a>
            </li>
          </ul>
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2024{" "}
            <a href="#" className="hover:underline">
              itstiny.link™
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
};

export default LandingPage;
